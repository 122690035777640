import { useEffect, useState } from "react";
import { togglePatientLocked } from "../../common/services/PatientService";
import { Alert, Modal } from "antd";

export default function LockPatientRecord(props) {

    const [display, setDisplay] = useState({
        name: null,
        date: null,
        id: null
    });

    useEffect(() => {
        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let splitVal = props.lockedByUser ? props.lockedByUser.split(",") : null;
        if (props.lockedByUser === null) {
            handleLock(true);
        } else {
            if (splitVal[0] !== userInfo.userId) {
                setDisplay({
                    id: splitVal[0],
                    name: splitVal[1],
                    date: splitVal[2]
                });

                props.toggleReadOnly(true);
            }
        }

        function beforeUnload(e: BeforeUnloadEvent) {
            handleLock(false);
        }

        window.addEventListener('beforeunload', beforeUnload);

        return () => {
            window.removeEventListener('beforeunload', beforeUnload);

            if (props.lockedByUser === null || splitVal[0] === userInfo.userId) {
                handleLock(false);
            }
        };
    }, []);

    function handleLock(toLock) {

        let userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let postdata = {
            userID: userInfo.userId,
            userFullName: userInfo.fullName,
            patientID: props.patientID,
            toLock: true
        }

        if (!toLock) {
            postdata.toLock = false;
            props.toggleReadOnly(postdata.toLock);

        }


        togglePatientLocked(postdata).then(data => {
            if (!toLock) {
                setDisplay({
                    name: null,
                    date: null,
                    id: null
                });
            }
        });
    }

    function openInfoModal() {
        Modal.info({
            title: `This record is currently locked by ${display.name} as of ${display.date}.`,
            content: (
                <div>
                    <p>This means that there is somebody actively working in this record.</p>
                    <p>To prevent data issues, please wait until they are done.</p>
                </div>
            ),
            onOk() { },
        });
    }

    return <div>
        {display.id !== null ?
            <div className="mb-3">
                <Alert message={<span>{`This record is currently locked by ${display.name} as of ${display.date}.`} <br /> <span className="color-pink hover" onClick={openInfoModal}>More Info</span></span>} type="warning" showIcon />
            </div>
            :
            <div className="mb-3">
                <Alert message={`You currently have this patient locked. This is so that any updates you make will not get overwritten by somebody else.
                                        Please make sure to leave this patient record once you are done.`} type="info" showIcon />
            </div>
        }
        {props.children}
    </div>
}