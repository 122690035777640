import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import { Select } from 'antd';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { SmartMessageBox } from "../../common/utils/functions";
import $ from 'jquery';

import Dropdown, { MenuItem, DropdownButton } from '@trendmicro/react-dropdown';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import {
    allProviders,
    allTechnologists,
    allInsuranceCompanies,
    allRecallTypes,
    allExamReasons,
    allCategories
} from '../../common/services/ApiService';

import { toast } from '@rickylandino/react-messages';

import ReactLoading from 'react-loading';

import Moment from 'moment';
import NumberFormat from 'react-number-format';

import { firstBy } from "thenby";

class AddExamPane extends Component {

    constructor(props) {
        super(props);

        let formFields = {
            examDateTime: new Date(),
            isExamCompleteYN: 0
        }

        if (props.facility.defaultExamType) {
            formFields.examTypeID = props.facility.defaultExamType;
        }
        if (props.facility.defaultTech) {
            formFields.performingTechUserID = props.facility.defaultTech;
        }
        if (props.patient) {
            formFields.insuranceID = props.patient.insuranceID;
        }
        if (props.patient) {
            formFields.providerID = props.patient.providerID;
        }

        let disableSaveBtn = true;

        if (formFields.examDateTime &&
            formFields.examTypeID &&
            formFields.performingTechUserID &&
            formFields.insuranceID &&
            formFields.providerID) {
            disableSaveBtn = false;
        }

        this.state = {
            showPane: true,
            formFields,
            persistentFormFields: formFields,
            patient: props.patient,
            examHistory: props.examHistory,
            patientExam: {},
            radiologist: {},
            density: {},
            recallType: {},
            examType: {},
            provider: {},
            providersList: [],
            insuranceCompanies: [],
            radiologistList: [],
            densityList: [],
            recallTypesList: [],
            recommendationsList: [],
            technologistList: [],
            biradsList: [],
            allBirads: [],
            allRecommendations: [],
            allDensities: [],
            allCategories: [],
            examTypes: [],
            showExamReasonDropdown: false,
            defaultComplete: false,
            examReasons: [],
            disableSaveBtn,
            patientExamID: null,
            categoryName: '',
            filteredExamTypes: [],
            loading: false,
            showSecondaryTechDropdown: false
        }

        this.isDirty = false;
        this.checkOnDirty = true;
        this.allowModal = true;
        this.popEventListnerAdded = false;

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        let props = this;
        if (!this.popEventListnerAdded) {
            $(window).on("popstate", function (event) {
                props.popEventListnerAdded = true;
                event.preventDefault();
                event.stopPropagation();

                if (props.isDirty && props.allowModal && Globals.isDirtyPage) {
                    SmartMessageBox(
                        {
                            title: "You are about to lose all changes.",
                            content:
                                "Are you sure you would like to proceed?",
                            buttons: "[No][Yes]"
                        },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                props.hidePane();
                                props.allowModal = false;
                                props.isDirty = false;
                                Globals.isDirtyPage = false;
                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                window.history.pushState(null, null, null);
                                return 0;
                            }
                        }
                    );
                }
            });
        }

        combineLatest(
            allProviders,
            allTechnologists,
            allInsuranceCompanies,
            allRecallTypes,
            allExamReasons,
            allCategories
        ).pipe(takeWhile(() => this.alive)).subscribe(([x1, x2, x3, x4, x5, x6]) => {
            
            let insuranceCompanies = x3.filter(i => i.facilityID === this.props.patient.facilityID);

            this.setState({
                insuranceCompanies,
                recallTypesList: x4,
                examReasons: x5,
                allCategories: [...x6]
            })
        });

        this.handleComponentMount();
    }

    handleComponentMount = () => {
        Axios.get(`/api/GetCategoryByFacility`, {
            params: {
                facilityID: this.props.patient.facilityID
            }
        }).then(response => {
            
            let categoryName = '';
            if (response.data.hasSingleCategory) {
                if (response.data.categoryID === '4d172b6f-936c-422c-9a81-de5befaa3ef7') {
                    categoryName = 'SCRMAM';
                }
            }

            var openExams = false;
            var showAllExamTypes = true;
            var allowAlwaysAddTypes = false;
            var showScreeningNewCat = false;

            var filteredExamTypes = [...response.data.examTypeList];
            var categoryIDs = [];


            this.state.examHistory.forEach(item => {

                var openExam = item.patientExamModel.isExamCompleteYN === 0 ? true : false;

                if (openExam) {
                    //allow all
                    openExams = true;

                    if (!item.examTypeModel.alwaysAllowAdd) {

                        //don't allow all
                        showAllExamTypes = false;

                        if (item.examTypeModel.examclass !== 'Screening') {
                            allowAlwaysAddTypes = true;

                        } else if (item.examTypeModel.examclass === 'Screening') {
                            showScreeningNewCat = true;

                            if (!categoryIDs.find(c => c === item.examTypeModel.categoryID)) {
                                categoryIDs.push(item.examTypeModel.categoryID);
                            }
                        }
                    }
                }
            });

            if (showScreeningNewCat) {
                //filteredExamTypes = filteredExamTypes.filter(et => (!categoryIDs.includes(et.categoryID) && et.examclass === 'Screening') || et.alwaysAllowAdd);
                filteredExamTypes = filteredExamTypes.filter(et => (!categoryIDs.includes(et.categoryID)) || et.alwaysAllowAdd);

            } else if (allowAlwaysAddTypes) {
                filteredExamTypes = filteredExamTypes.filter(et => et.alwaysAllowAdd);
            } 

            const examTypeID = filteredExamTypes.find(fet => fet.examTypeID === this.state.formFields.examTypeID)?.examTypeID;

            let formFields = {...this.state.formFields };

            formFields.examTypeID = examTypeID;

            this.setState({
                biradsList: response.data.biradList,
                recommendationsList: response.data.recommendationList,
                densityList: response.data.densityList,
                examTypes: response.data.examTypeList,
                radiologistList: response.data.radiologistList,
                filteredExamTypes,
                categoryName,
                formFields,
                technologistList: response.data.technologists,
                providersList: response.data.providers
            });

        }).catch(error => {
            console.log(error);
        });
    }

    hidePane = () => {

        if (Globals.isDirtyPage) {
            SmartMessageBox(
                {
                    title: "You are about to lose all changes.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: "[No][Yes]"
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

                        if (this.state.patientExamID) {
                            let postdata = {
                                uniqueID: this.state.patientExamID,
                                userID: userInfo.userId
                            };

                            Axios.post(`/api/DeletePatientExam`, postdata
                            ).then(response => {
                                toast.success("Exam has been deleted");
                            }).catch(error => {
                                console.log(error);
                            });
                        }

                        this.setState({
                            showPane: false
                        }, () => { setTimeout(() => this.props.hidePane(), 1000) });
                        this.allowModal = false;
                        this.isDirty = false;
                        Globals.isDirtyPage = false;
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else {
            this.setState({
                showPane: false
            }, () => { setTimeout(() => this.props.hidePane(), 1000) });
        }
    }

    handleExamTypeInputChange = (key, event) => {
        const target = event.target;
        var value = key;
        const name = "examTypeID";

        let formFields = this.state.formFields;


        let showExamReasonDropdown = this.state.examTypes.find(et => et.examTypeID === value)?.reasonforExamPromptYN === 1 ? true : false;
        let defaultComplete = this.state.examTypes.find(et => et.examTypeID === value)?.defaultComplete === 1 ? true : false;

        let showSecondaryTechDropdown =
            value === '4861a70d-ee46-49db-a8d1-e3f26e62a1a3' ||
            value === '6cde7eb2-f30d-4560-a664-c71f601c1c1c' ||
            value === '6E503842-341E-4207-8175-8A9BF54DFC51'.toLowerCase() ||
            value === 'BD681866-1B2C-4927-B737-BCA7BD6CE8BD'.toLowerCase() ||
            value === '5EB88935-BFBA-4D7F-A29F-B8EC06107D83'.toLowerCase() ||
            value === 'F7434C4E-8ED0-4982-9964-E3519311F55F'.toLowerCase() ||
            value === '47C1F215-2995-4D57-BD9F-089246445AC0'.toLowerCase() ||
            value === 'B107267C-C57C-4F91-B8EF-D6CB52EA6F15'.toLowerCase() ||
            value === '429887FA-EBBB-4EEE-BE0F-998FDD778309'.toLowerCase() ||
            value === 'CF71D0B0-FBC5-400D-821A-277B67AA1D17'.toLowerCase();

        if (!showSecondaryTechDropdown) {
            formFields.ultrasoundTechUserID = null;
        }

        if (defaultComplete) {
            formFields.isExamCompleteYN = 1
        } else {
            formFields.isExamCompleteYN = 0
        }

        formFields.reasonforExamID = showExamReasonDropdown ? this.state.formFields.showExamReasonDropdown : null;
        formFields.examTypeID = value;

        let disableSaveBtn = this.state.disableSaveBtn;

        if (formFields.examDateTime &&
            formFields.examTypeID &&
            formFields.performingTechUserID &&
            formFields.insuranceID &&
            formFields.providerID) {
            disableSaveBtn = false;
        }

        this.setState({
            showExamReasonDropdown,
            formFields,
            disableSaveBtn,
            showSecondaryTechDropdown
        }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }
        });

        document.body.click();
    }


    handleInputChange = (event) => {
        const target = event.target;
        var value = null;
        if (target.type === 'checkbox') {
            value = target.checked === true ? 1 : 0;
        }
        else {
            value = target.value;
        }
        const name = target.name;

        let formFields = this.state.formFields;

        if (name === 'examTypeID') {

            let showExamReasonDropdown = (value && this.state.examTypes.find(et => et.examTypeID === value).reasonforExamPromptYN === 1) ? true : false;
            let defaultComplete = (value && this.state.examTypes.find(et => et.examTypeID === value).defaultComplete === 1) ? true : false;

            if (defaultComplete) {
                formFields.isExamCompleteYN = 1
            } else {
                formFields.isExamCompleteYN = 0
            }

            this.setState({
                showExamReasonDropdown,
                defaultComplete,
                formFields: { ...formFields, reasonforExamID: showExamReasonDropdown ? this.state.formFields.showExamReasonDropdown : null }
            });
        } else if (name === 'supervised') {
            let formFields = this.state.formFields;
            formFields.supervisingTechUserID = value === 1 ? formFields.supervisingTechUserID : null;

            this.setState({
                formFields
            });
        }

        formFields = { ...this.state.formFields, [name]: value }

        let disableSaveBtn = this.state.disableSaveBtn;

        if (formFields.examDateTime &&
            formFields.examTypeID &&
            formFields.performingTechUserID &&
            formFields.insuranceID &&
            formFields.providerID) {
            disableSaveBtn = false;
        }
        
        this.setState({ formFields, disableSaveBtn }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }
        });
    }

    handleDateChange = (name, date) => {

        let formFields = { ...this.state.formFields, [name]: date }

        let disableSaveBtn = this.state.disableSaveBtn;

        if (formFields.examDateTime &&
            formFields.examTypeID &&
            formFields.performingTechUserID &&
            formFields.insuranceID &&
            formFields.providerID) {
            disableSaveBtn = false;
        }

        this.setState({ formFields, disableSaveBtn }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }
        });
    }

    handleSubmit = () => {
        let formFields = this.state.formFields;
        formFields.facilityID = this.state.patient.facilityID;
        formFields.patientID = this.state.patient.patientID;
        formFields.reasonforExamID = this.state.formFields.reasonforExamID ? this.state.formFields.reasonforExamID : null;

        if ((this.state.showExamReasonDropdown && formFields.reasonforExamID === null) ||
            (this.state.showSecondaryTechDropdown && !formFields.ultrasoundTechUserID) ||
            (formFields.supervised === 1 && !formFields.supervisingTechUserID) || (
            !formFields.examTypeID ||
            !formFields.performingTechUserID ||
            !formFields.insuranceID ||
            !formFields.providerID ||
            !formFields.examDateTime
            )) {

            toast.warning("Please fill in all required information.");

        } else {
            this.setState({
                loading: true
            });

            formFields.examDateTime = Moment(formFields.examDateTime).utc().toDate();

            const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
            formFields.uid = userInfo.userId;
            Axios.post(`/api/InsertPatientExam`, formFields
            ).then(response => {
                this.isDirty = false;
                Globals.isDirtyPage = false;

                toast.success("Patient exam has been added.");

                this.setState({
                    patientExam: response.data,
                    patientExamID: response.data.patientExamID,
                    loading: true
                });

                this.props.updateExamInfo();
                this.props.updatePatientInfo();
                this.hidePane();
            }).catch(error => {
                toast.error("Something went wrong");
            });
        }
    }

    render() {
        //const sortedExamTypes = this.state.filteredExamTypes.sort((a, b) => (a.categoryID > b.categoryID) ? 1 : -1);
        let fet = [...this.state.filteredExamTypes];

        fet.sort(firstBy("categoryID"));

        function groupBy(arr, property) {
            return arr.reduce(function (memo, x) {
                if (!memo[x[property]]) { memo[x[property]] = []; }
                memo[x[property]].push(x);
                return memo;
            }, {});
        }

        var o = groupBy(fet, 'categoryID');

        var sortedExamTypes = [];

        for (const property in o) {
            o[property].sort(
                firstBy("examType")
            );

            sortedExamTypes.push(...o[property]);
        }

        const theme = window.sessionStorage.getItem("theme");

        return (
            <SlidingPane
                className='some-custom-class'
                overlayClassName='pinkCard'
                className={theme === 'dark' ? "dark-theme" : 'light-theme'}
                isOpen={this.state.showPane}
                title='Exam Details'
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body" ref={(div) => {
                    this.messageList = div;
                }}>
                    <div className="row justify-content-end">
                        <div className="form-group col-12 text-right">
                            <span className="ml-3 fs-24 color-pink">*</span> = Required Field
                        </div>
                    </div>
                    <Card className="pinkCard">
                        <Card.Header><span>Exam</span>
                        </Card.Header>
                        <Card.Body>

                            <div className="row">
                                <div className="form-group col-lg-3">
                                    <label className="form-label">Exam Date <span className="color-pink">*</span></label>
                                    <DatePicker
                                        selected={this.state.formFields.examDateTime}
                                        onChange={(date) => this.handleDateChange('examDateTime', date)}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />
                                </div>

                                
                                    <div className="form-group col-lg-5">
                                    <label className="form-label">Exam Type <span className="color-pink">*</span></label>
                                    {this.state.filteredExamTypes.length > 0 ?
                                        <Fragment>
                                            {this.state.allCategories.length > 0 ?
                                                <Dropdown
                                                    onSelect={(eventKey, event) => {
                                                        this.handleExamTypeInputChange(eventKey, event);
                                                    }}
                                                    className="custom-fancy-dropdown"
                                                >
                                                    <DropdownButton
                                                        title={this.state.examTypes.find(et => et.examTypeID === this.state.formFields.examTypeID)?.examType || ''}
                                                        className="form-control-custom"
                                                        onSelect={(eventKey) => {
                                                        }}
                                                    >

                                                        {sortedExamTypes.map((type, idx) => (
                                                            <Fragment key={idx}>
                                                                {type.categoryID !== sortedExamTypes[idx - 1]?.categoryID &&
                                                                    <MenuItem header className="color-pink">{this.state.allCategories?.find(cat => cat.categoryID === type.categoryID)?.description}</MenuItem>
                                                                }
                                                                <MenuItem key={idx} eventKey={type.examTypeID} value={type.examTypeID} onSelect={(eventKey, event) => {
                                                                    this.handleExamTypeInputChange(eventKey, event);
                                                                }}>{type.examType}</MenuItem>
                                                            </Fragment>
                                                        ))}
                                                    </DropdownButton>
                                                </Dropdown>
                                                :
                                                <Fragment>
                                                    <select className="form-control-custom" value={this.state.formFields.examTypeID} name="examTypeID" onChange={this.handleInputChange}>
                                                        <option value='' />
                                                        {this.state.filteredExamTypes.map((type, idx) => <option key={idx} value={type.examTypeID} onChange={this.handleInputChange}>{type.examType}</option>)}
                                                    </select>
                                                </Fragment>
                                            }
                                        </Fragment>
                                        :
                                        <input className="form-control-custom no-border" defaultValue="No available exam types" />
                                    }
                                </div>
                                <div className="col-lg-2">
                                    <label className="form-label">Complete</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" className="custom-control-input" name="isExamCompleteYN" value={this.state.formFields.isExamCompleteYN || ''} onChange={this.handleInputChange}
                                                checked={(this.state.formFields.isExamCompleteYN === 1 || this.state.defaultComplete) ? true : false} disabled={this.state.defaultComplete} />
                                            <label className="custom-control-label"></label>
                                        </div>
                                    </div>
                                </div>

                                {this.props.facility?.supervisingTech &&
                                    <div className="col-lg-2">
                                        <label className="form-label">Supervised</label>
                                        <div className="form-control-custom no-border">
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" className="custom-control-input" id="supervised" name="supervised" value={this.state.formFields.supervised || ''} onChange={this.handleInputChange}
                                                    checked={this.state.formFields.supervised === 1 ? true : false} />
                                                <label className="custom-control-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="form-group col-lg-4">
                                    <label className="form-label">{this.state.showSecondaryTechDropdown ? 'Mammography Tech' : 'Technologist'} <span className="color-pink">*</span></label>
                                    <select className="form-control-custom" value={this.state.formFields.performingTechUserID || ''} name="performingTechUserID" onChange={this.handleInputChange}>
                                        <option value='' />
                                        {this.state.technologistList.map((tech, idx) => <option key={idx} value={tech.userID} onChange={this.handleInputChange}>{tech.fName} {tech.lName}</option>)}

                                    </select>
                                </div>

                                <div className="form-group col-lg-4">
                                    <label className="form-label">Insurance <span className="color-pink">*</span></label>
                                    <select className="form-control-custom" value={this.state.formFields.insuranceID || ''} name="insuranceID" onChange={this.handleInputChange}>
                                        <option value=''/>
                                        {this.state.insuranceCompanies.map((company, idx) => <option key={idx} value={company.insuranceID} onChange={this.handleInputChange}>{company.insCompName}</option>)}

                                    </select>
                                </div>

                                <div className="form-group col-lg-4">
                                    <label className="form-label">Referring MD <span className="color-pink">*</span></label>
                                    <Select
                                        allowClear
                                        placeholder="Please select"
                                        onChange={(value) => this.setState({ formFields: { ...this.state.formFields, providerID: value } })}
                                        className="form-control-custom w-100"
                                        bordered={false}
                                        value={this.state.formFields.providerID}
                                        options={this.state.providersList.map(prov => ({ label: prov.fName + ' ' + prov.lName + ', ' + prov.title, value: prov.providerID }))}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        showSearch
                                        disabled={this.state.patientInactive}
                                    />
                                </div>

                                {this.state.showSecondaryTechDropdown &&
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Ultrasound Tech <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={this.state.formFields.ultrasoundTechUserID || ''} name="ultrasoundTechUserID" onChange={this.handleInputChange}>
                                            <option value='' />
                                            {this.state.technologistList.map((tech, idx) => <option key={idx} value={tech.userID} onChange={this.handleInputChange}>{tech.fName} {tech.lName}</option>)}

                                        </select>
                                    </div>
                                }

                                {this.state.showExamReasonDropdown &&
                                    <div className="form-group col-lg-4">
                                    <label className="form-label">Exam Reason <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={this.state.formFields.reasonforExamID || ''} name="reasonforExamID" onChange={this.handleInputChange}>
                                            <option value='' />
                                            {this.state.examReasons.map((reason, idx) => <option key={idx} value={reason.reasonforExamID}>{reason.reasonforExam}</option>)}

                                        </select>
                                    </div>
                                }

                                {this.state.formFields.supervised === 1 &&
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Supervising Tech <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={this.state.formFields.supervisingTechUserID} name="supervisingTechUserID" onChange={this.handleInputChange}>
                                            <option />
                                            {this.state.technologistList.map((tech, idx) => <option key={idx} value={tech.userID} onChange={this.handleInputChange}>{tech.fName} {tech.lName}</option>)}

                                        </select>
                                    </div>
                                }
                            </div>

                        </Card.Body>
                    </Card>
                </div>

                <div className="modal-footer">
                    {this.state.loading ? 
                        <div className="form-group col-12 padding-25-10">
                            <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#F30079'} height={30} width={30} />
                        </div>
                        :
                        <div className="form-group col-12 padding-25-10">
                            <button className="btn btn-submit" onClick={this.handleSubmit} disabled={this.state.disableSaveBtn || this.state.filteredExamTypes.length === 0}>Save</button>

                            <button className="btn btn-outline-default margin-left-15" onClick={this.hidePane}>Cancel</button>
                        </div>
                    }
                    
                </div>
            </SlidingPane>
        );
    }
}

export default withRouter(AddExamPane);