import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import { Select, message } from 'antd';
import NumberFormat from 'react-number-format';

import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { userAssociatedFacilities, downloadOpenExamsList } from '../../../common/services/ApiService';

import Axios from '../../../config/axios';

import Moment from 'moment';

import { toast } from '@rickylandino/react-messages';

class OpenExamsReportModal extends Component {
    constructor(props) {
        super(props);

        var minus7 = new Date();
        minus7.setDate(minus7.getDate() - 7);

        var twoYearsAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 2))

        this.state = {
            showModal: props.showModal,
            allFacilities: [],
            selectedFacilities: [],
            availableBirads: [],
            selectedBirads: [],
            selectedDates: {
                startDate: twoYearsAgo,
                endDate: new Date()
            }
        }

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        combineLatest(
            userAssociatedFacilities
        ).pipe(takeWhile(() => this.alive)).subscribe(([f]) => {
            if (f?.length > 0) {

                let selectedFacilities = [];

                if (f.length === 1) {
                    selectedFacilities = [f[0].facilityModel.facilityID];
                }

                this.setState({
                    allFacilities: f,
                    selectedFacilities,
                    loaded: true
                }, () => {
                        if (f.length === 1) {
                            this.handleFacilityChange(selectedFacilities);
                        }
                    
                });
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleFacilityChange = (selectedFacilities) => {
        if (!this.state.selectedFacilities.length > 0) {
            let availableBirads = this.state.allFacilities.find(f => f.facilityModel.facilityID === selectedFacilities[0])?.availableBirads;
            this.setState({
                selectedFacilities,
                availableBirads
            });
        } else {

            //create list with all birads, including duplicates
            let concatBirads = [];
            selectedFacilities.forEach(f => {

                let facBirads = this.state.allFacilities.find(fac => fac.facilityModel.facilityID === f)?.availableBirads;

                concatBirads = concatBirads.concat(facBirads);
            });

            //filter duplicates out so we are left with only unique birads
            const seen = new Set();

            const availableBirads = concatBirads.filter(el => {
                const duplicate = seen.has(el.birads);
                seen.add(el.birads);
                return !duplicate;
            });

            //sort by alpabetical order
            availableBirads.sort(function (a, b) {
                var nameA = a.birads.toUpperCase(); // ignore upper and lowercase
                var nameB = b.birads.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                // names must be equal
                return 0;
            });


            this.setState({
                selectedFacilities,
                availableBirads
            })
        }
    }

    handleBiradChange = (selectedBirads) => {
        this.setState({
            selectedBirads
        });
    }

    handleClear = () => {
        this.setState({
            selectedFacilities: [],
            selectedBirads: [],
            availableBirads: [],
            selectedDates: {
                startDate: null,
                endDate: new Date()
            }
        })
    }

    handleNewDateChange = (name, date) => {
        this.setState({ selectedDates: { ...this.state.selectedDates, [name]: date } });
    }

    handleSubmit = () => {

        let endDate = this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null;
        let startDate = this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null;

        if (this.state.selectedFacilities.length > 0
            && this.state.selectedBirads.length > 0) {
            const q = Axios.defaults.baseURL + "api/RenderOpenExamReport";

            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = q;
            form.style.display = "none";

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "startDate";
            input.value = startDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "endDate";
            input.value = endDate;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "selectedFacilies";
            input.value = this.state.selectedFacilities;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "birads";
            input.value = this.state.selectedBirads;
            form.appendChild(input);

            var input = document.createElement("input");
            input.type = "hidden";
            input.name = "ianaTimezone";
            input.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
            form.appendChild(input);

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        } else {
            toast.error("Please fill in all required fields.");
        }

    }

    exportExcel = () => {
        let endDate = this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null;
        let startDate = this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null;

        if (this.state.selectedFacilities.length > 0
            && this.state.selectedBirads.length > 0) {
            const key = "loading";
            message.loading({ content: 'Exporting Open Exams...', key, duration: 0 });
            let fileName = "open-exams_" + Moment().format('YYYYMMDDHm') + '.xlsx';

            let postdata = {
                fileName,
                sDate: this.state.selectedDates.startDate ? Moment(this.state.selectedDates.startDate).format("YYYY-MM-DD") : null,
                eDate: this.state.selectedDates.endDate ? Moment(this.state.selectedDates.endDate).format("YYYY-MM-DD") : null,
                facilities: this.state.selectedFacilities,
                birads: this.state.selectedBirads,
                ianaTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }

            downloadOpenExamsList(postdata).then(data => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = fileName;//"File.xlsx";
                //document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);

                message.destroy(key);

                this.setState({
                    downloading: false
                })
            }).catch(error => {
                console.log("Error");
            });
        } else {
            toast.error("Please fill in all required fields.");
        }
    }

    handleFilterCustom = (input, option) => {
        //console.log(input);
        //console.log(option);
    }


    render() {

        const { Option } = Select;

        const theme = window.sessionStorage.getItem("theme");

        return (
            <Modal size='lg' show={this.state.showModal} onHide={this.toggleModal} backdrop='static' dialogClassName={theme === 'dark' ? 'dark-theme' : 'light-theme'}>
                <Modal.Header>
                    <h1>Open Exams</h1>
                </Modal.Header>
                <Modal.Body className="no-y-overflow">
                    <div className="panel-content">
                        {this.state.loaded &&
                            <div className="row">

                                <div className="form-group col col-6">
                                    <div className="form-label">Start Range</div>
                                    <DatePicker
                                        onChange={(date) => this.handleNewDateChange('startDate', date)}
                                        selectsStart
                                        startDate={this.state.selectedDates.startDate}
                                        endDate={this.state.selectedDates.endDate}
                                        className="form-control-custom"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                        placeholderText='Start Date'
                                        selected={this.state.selectedDates.startDate && Moment(this.state.selectedDates.startDate).toDate()}
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />
                                </div>
                                <div className="col col-6">
                                    <div className="form-label">End Range</div>
                                    <DatePicker
                                        onChange={(date) => this.handleNewDateChange('endDate', date)}
                                        selectsEnd
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                        startDate={this.state.selectedDates.startDate}
                                        endDate={this.state.selectedDates.endDate}
                                        className="form-control-custom"
                                        placeholderText='End Date'
                                        selected={this.state.selectedDates.endDate && Moment(this.state.selectedDates.endDate).toDate()}
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />

                                </div>
                                <div className="form-group col col-lg-6 col-12">
                                <div className="form-label">Facility <span className="color-pink">*</span></div>
                                {this.state.allFacilities.length === 1 ?
                                    <div>{this.state.allFacilities[0].facilityModel.facilityName}</div>
                                    :
                                    <Select
                                        mode="multiple"
                                        maxTagCount={2}
                                        allowClear
                                        placeholder="Please select"
                                        onChange={this.handleFacilityChange}
                                        className="form-control-custom w-100"
                                        bordered={false}
                                        virtual={false}
                                        value={this.state.selectedFacilities}
                                        showSearch
                                        filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                                    >
                                        {this.state.allFacilities.map((fac, idx) => <Option key={idx} key={fac.facilityModel.facilityID} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                                    </Select>
                                }
                                    
                                </div>
                                <div className="form-group col col-lg-6 col-12">
                                    <div className="form-label">BIRADS <span className="color-pink">*</span></div>
                                    <Select
                                        mode="multiple"
                                        maxTagCount={1}
                                        allowClear
                                        placeholder="Please select"
                                        onChange={this.handleBiradChange}
                                        className="form-control-custom w-100"
                                    bordered={false}
                                    virtual={false}
                                        value={this.state.selectedBirads}
                                >
                                        <Option key="No Result Entered" value="No Result Entered">No Result Entered</Option>
                                        {this.state.availableBirads?.map((b, idx) => <Option key={b.birads} value={b.birads}>{b.birads}</Option>)}
                                    </Select>
                                </div>
                            </div>


                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-submit float-left" onClick={this.handleSubmit} style={{ left: '10px', position: 'fixed', backgroundColor: '#1D6F42', borderColor: '#1D6F42'}}>Export to PDF</button>
                    <div className="row">
                        <div className="form-group col-12 padding-25-10">
                            
                            <button className="btn btn-submit" onClick={this.exportExcel}>Run Report</button>
                            <button className="btn btn-outline-default margin-left-15" onClick={this.handleClear}>Clear</button>
                            <button className="btn btn-outline-default margin-left-15" id="closeModal" onClick={() => this.props.hideModal()}>Cancel</button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(OpenExamsReportModal);